export const translate = {
    arm:{
        tr_nameOfOrganization : "Երևանի Մաթեմատիկական Մեքենաների ",
        tr_nameOfOrganization2 : "Գիտահետազոտական Ինստիտուտ",
        tr_leaders:"Ղեկավարներ",
        tr_history: "Պատմություն",
        tr_work: "Աշխատանք",
        tr_partners : "Գործընկերներ", 
        tr_news : "Նորություններ",
        tr_announcements: "Հայտարարություններ",
        tr_product: "Արտադրանք",
        tr_infoCenter: "Տեղեկատվական կենտրոն",
        tr_contacts : "Հետադարձ կապ",
        tr_galery: "Տեսադարան",
        tr_search: "Որոնում",
        tr_address: 'Հասցե`',
        tr_address2: 'ք․ Երևան, Հ.Հակոբյան 3',
        tr_tel : 'Հեռ՝',
        tr_fax : 'Ֆաքս՝',
        tr_mail: 'էլ.-Հասցե՝ ',
        tr_Apply:'Դիմել առցանց',
        tr_name:'Անուն',
        tr_content: 'Ձեր հայտը․․․',
        tr_send: "Ուղարկել",
        tr_aboutMuseum:'Թանգարանի մասին',
        tr_Book: 'Ամրագրում',
        tr_date: 'Ընտրե՛լ օրն ու ժամը:',
        tr_fullName:'Անուն Ազգանուն:',
        tr_telephone: 'Հեռախոս:',
        tr_persons: 'Անձանց քանակ:',
        tr_sendBook: "Ամրագրել",
        tr_expo: 'Երևան էքսպո',
        tr_futureExhibition: 'Կայանալիք ցուցահանդեսներ',
        tr_pastExhibition: 'Կայացած ցուցահանդեսներ',
        tr_noPublished:"Հրապարակումներ դեռ չկան"
    },
    ru:{
        tr_nameOfOrganization : "Ереванский Научно-Исследовательский ",
        tr_nameOfOrganization2 : "Институт Математических Машин",
        tr_leaders:"Лидеры",
        tr_fontSize : "Размер шрифта",
        tr_history: "История",
        tr_work: "Вакансии",
        tr_partners : "Партнеры",
        tr_news : "Новости",
        tr_announcements: "Объявления",
        tr_product: "Продукт",
        tr_contacts : "Связаться с нами",
        tr_galery: "Галерея",
        tr_search: "Поиск",
        tr_address: 'Адрес',
        tr_address2: 'г․ Ереван, А. Акопян 3',
        tr_tel : 'Тел.',
        tr_fax : 'Факс',
        tr_mail: 'Эл․ почта ',
        tr_Apply:'Подать заявку ',
        tr_name:'Имя',
        tr_content: 'Ваша заявка․․․',
        tr_send: "Отправить",
        tr_aboutMuseum:'О музее',
        tr_Book: 'Бронирование',
        tr_date: 'Выберите дату и время.',
        tr_fullName:'Имя Фамилия:',
        tr_telephone: 'Телефон:',
        tr_persons: 'Количество человек.',
        tr_sendBook: "Забронировать",
        tr_expo: 'Ереван Экспо',
        tr_futureExhibition: 'Будущие Выставки',
        tr_pastExhibition: 'Старые выставки',
        tr_noPublished: "Пока нет публикаций"
    },
    eng:{
        tr_nameOfOrganization : "Yerevan Computer Research Development Institute",
        tr_nameOfOrganization2 : "Development Institute",
        tr_leaders:"Leaders",
        tr_fontSize : "Font Size",
        tr_history: "History",
        tr_work: "Vacancies",
        tr_partners : "Partners",
        tr_news : "News",
        tr_announcements: "Announcements",
        tr_product: "Products",
        tr_contacts : "Contact us",
        tr_galery: "Gallery",
        tr_search: "Search",
        tr_address: 'Address',
        tr_address2: 'Yerevan, H.Hakobyan 3',
        tr_tel : 'Tel.',
        tr_fax : 'Fax',
        tr_mail: 'Email ',
        tr_Apply:'Apply online',
        tr_name:'Name',
        tr_email:'Email',
        tr_content: 'Your application ',
        tr_send: "Send",
        tr_aboutMuseum:'About Museum',
        tr_Book: 'Booking',
        tr_date: 'Choose Date and Time:',
        tr_fullName:'Full Name',
        tr_telephone: 'Telephone',
        tr_persons: 'Number of Visitors',
        tr_sendBook: "Book",
        tr_expo: 'yerevan Expo',
        tr_futureExhibition: 'Coming Exhibitions',
        tr_pastExhibition: 'Past Exhibitions',
        tr_noPublished: "No posts yet"
    }
}