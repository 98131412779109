import React from 'react'
import styles from './mainAndFooter.module.css'
import { menu } from '../Menu/menuList'
import FooterMenu from './FooterMenu'

export default function Footer() {
  return (
    <div className={styles['footer-div']}>
        <div className={styles['map-div']}>
                {menu.map(el => (
                    
                        <FooterMenu key={el.category} el={el} />
                ))}
        </div>
        <div className={styles['copyright-div']}>
            © Mergelyan, 2024. All Rights Reserved by YCRDI.
        </div>
    </div>
  )
}
