import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { store } from '../HOC/State'
import MenuItems from './MenuItems'
import styles from './menu.module.css'

export default function Menu() {

    const {showMenu,setShowMenu} = useContext(store)

  return (
    // <div onClick={(e) => e.stopPropagation()} className={showMenu ? styles['menu-div'] : `${styles['menu-div']} ${styles['menu-div-closed']}`}>
    //     <div className={showMenu ? styles['open-arrow-div'] : `${styles['open-arrow-div']} ${styles.opacity}`} onClick={() => setShowMenu(!showMenu)}>
    //         {showMenu ? <FontAwesomeIcon className={styles['open-arrow']} icon={faAnglesLeft} /> : <FontAwesomeIcon className={styles['open-arrow']} icon={faAnglesRight} />}
    //     </div>
    //     <MenuItems/>
    // </div>
    <div>
        <MenuItems/>
    </div>
  )
}
