import React, { useContext, useState } from 'react'
import LangModal from './LangModal'
import { langObj } from './langObj'
import styles from './lang.module.css'
import { store } from '../../HOC/State'

export default function Lang() {
const {selectedLang, langChange} = useContext(store)
const [openModal, setOpenModal] = useState(false)

const closeModal = () => {
    setOpenModal(false)
}
const openCloseModal = (e) => setOpenModal(!openModal)
  return (
    <div>
        <div className={styles['lang-ordinary']} onClick={openCloseModal}>
            {/* <img  src={`/${selectedLang}lang.png`} alt={langObj[selectedLang]} /> */}
            <p>{langObj[selectedLang]}</p>
        </div>
        {openModal && <LangModal change={langChange} closeModal={closeModal} />}
    </div>
  )
}
