import React from 'react'
import { langObj } from './langObj'
import styles from './lang.module.css'

export default function LangModal({change, closeModal}) {
  return (
    <div className={styles['lang-choose']}>
         <div className={styles['lang-items']} onClick={() => {change("arm"); closeModal()}}>
             {/* <img className={styles['lang-image']} alt='armLang' src={`/armlang.png`} />s */}
                 {langObj.arm}
         </div>
         <div className={styles['lang-items']} onClick={() => {change("eng"); closeModal()}}>
             {/* <img className={styles['lang-image']} alt='engLang' src={`/englang.png`} /> */}
                 {langObj.eng}
         </div>
         <div className={styles['lang-items']}  onClick={() => {change("ru"); closeModal()}}>
             {/* <img className={styles['lang-image']} alt='ruLang' src={`/rulang.png`} /> */}
                 {langObj.ru}
         </div>
    </div>
  )
}
