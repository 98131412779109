import React, { useContext, useEffect, useState } from 'react'
import MenuItem from './MenuItem'
import {menu} from './menuList'
import { store } from '../HOC/State'
import styles from './menu.module.css'

export default function MenuItems() {
  const [isOpen, setIsOpen] = useState(null)

  const {showMenu,setShowMenu} = useContext(store)

  useEffect(
    () => {
      if(!showMenu){
        setIsOpen(null)
      }
    }, [showMenu])
  


  return (
    <div className={styles['menu-items-div']}>
        {
          menu.map((el, i) => <MenuItem key={i} el={el} isOpen={isOpen} setIsOpen={setIsOpen}/>)
        }
    </div>
  )
}
