import React, { useContext, useEffect, useState } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass} from  '@fortawesome/free-solid-svg-icons'
import styles from './search.module.css'
import { useLocation, useNavigate } from 'react-router'
import { store } from '../HOC/State'
import { translate } from '../../Pages/translate'

export default function Search() {
    const [openInput, setOpenInput] = useState(false)
    const [search, setSearch] = useState("")

    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]

    const navigate = useNavigate()
    const location = useLocation()

    const showOrSend = async () =>{
        if(search){
          navigate(`/search/${search}`)
        }else{
          setOpenInput(!openInput)
        }
    }

    useEffect(() => {
      if(location.pathname.includes('search')){
        if(!search){
          navigate(-1)
        }
      }
    }, [search])
  return (
    <div className={!openInput ? styles['shearch-div'] : `${styles['shearch-div']} ${styles['search-div-border']}`}>
        <input className={!openInput ? `${styles['search-input']}} ${styles['search-input-hide']}` : styles['search-input']} type='text' placeholder={lang.tr_search} value={search} onChange={e => setSearch(e.target.value)}/>
        <FontAwesomeIcon className={styles['search-icon']} icon={faMagnifyingGlass} onClick={showOrSend}/>
        {!openInput && <div style={{height: "1px"}}></div>}
    </div>
  )
}
